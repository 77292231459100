<script setup lang="ts">
import { computed } from "vue";
import RiseSpinner from "./RiseSpinner.vue";

const {
  type = "button",
  text = "Sample Text",
  variant = "primary",
  size = "base",
  iconLeft = null,
  iconRight = null,
  iconSize = "base",
  fill = false,
  loading = false,
  disabled = false,
} = defineProps<{
  type?: "button" | "submit" | "reset";
  text: string;
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  iconLeft?: string | Array<any> | object | null;
  iconRight?: string | Array<any> | object | null;
  iconSize?: keyof typeof iconSizes;
  fill?: boolean;
  loading?: boolean;
  disabled?: boolean;
}>();

const variants = {
  primary:
    "bg-gradient-to-r from-[#3CA4E0] to-[#4E62D8] text-white hover:[background:linear-gradient(86.42deg,_#86B9E4_2.94%,_#808BDC_50.83%,_#808CDC_97.43%)] focus:ring focus:ring-blue-400",
  secondary:
    "bg-gradient-to-r from-[#5E59EC] to-[#1F2B38] text-white hover:[background:linear-gradient(90deg,_#7E7AEF_0%,_#4D5662_100%)] focus:ring focus:ring-primary-300",
  tertiary:
    "dark:bg-gray-700 text-gray-900 dark:text-white dark:hover:bg-gray-600 hover:bg-gray-100 border border-primary-400 focus:ring focus:ring-primary-200",
  white:
    "dark:bg-white text-primary-600 hover:bg-gray-200 focus:ring focus:ring-white",
  transparent:
    "bg-transparent text-white border border-white hover:text-primary-500 hover:bg-white focus:ring focus:ring-primary-400",
  outline:
    "bg-white text-gray-800 dark:text-white ring-1 hover:bg-gray-800  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 hover:text-white disabled:bg-transparent disabled:text-gray-400 disabled:ring-gray-400",
  "outline-white":
    "bg-white/10 text-white ring-1 ring-inset ring-gray-200 hover:bg-white hover:ring-white hover:text-gray-800",
  "outline-gradient":
    "bg-white text-gray-800 ring-1 ring-inset ring-primary-600 hover:bg-primary-800 hover:ring-gray-800",
  link: "bg-white text-primary-600 hover:text-primary-700",
  disabled:
    "bg-gray-200 text-gray-700 hover:bg-gray-300 focus:ring focus:ring-gray-100",
  danger:
    "bg-red-600 text-white hover:bg-red-700 focus:ring focus:ring-red-100",
};

const sizes = {
  xs: "py-2 px-3 rounded-lg text-xs font-medium leading-[18px]",
  sm: "py-2 px-3 rounded-lg text-sm font-medium leading-[21px]",
  base: "py-[10px] px-5 rounded-lg text-sm font-medium leading-[21px]",
  l: "py-3 px-5 rounded-lg text-base font-medium leading-[24px]",
  xl: "py-[14px] px-6 rounded-lg text-base font-medium leading-[24px]",
};

const iconSizes = {
  sm: "text-sm",
  base: "text-base",
  l: "text-lg",
};

const variantClasses = computed(() => {
  if (disabled) return variants.disabled;
  return variants[variant];
});
</script>

<template>
  <button
    :type="type"
    class="group flex h-fit items-center justify-center outline-none transition-all"
    :class="[
      variantClasses,
      sizes[size],
      { 'w-full': fill, 'w-fit': !fill },
      { 'pointer-events-none': loading || disabled },
    ]"
    :disabled="disabled"
  >
    <RiseSpinner
      v-if="loading"
      class="mr-2 h-[14px] w-[14px] !fill-gray-800 !text-gray-900/25"
    />
    <div
      v-if="$slots['append-left'] && !loading"
      class="mr-2 flex items-center justify-center"
      :class="[iconSizes[iconSize]]"
    >
      <slot name="append-left" />
    </div>
    <i
      v-if="iconLeft && !loading"
      class="mr-2 ri-fw font-semibold"
      :class="[
        iconSizes[iconSize],
        { '!h-[12px] !w-[12px]': ['xs', 'sm'].includes(sizes[size]) },
        `ri-${iconLeft}`,
      ]"
    />
    <div
      class="whitespace-nowrap"
      :class="{
        'bg-gradient-to-r from-[#3CA4E0] to-[#4E62D8] bg-clip-text text-transparent group-hover:text-white':
          variant === 'outline-gradient',
      }"
    >
      {{ text }}
    </div>
    <i
      v-if="iconRight && !loading"
      class="ml-2 ri-fw font-semibold"
      :class="[
        iconSizes[iconSize],
        { '!h-[12px] !w-[12px]': ['xs', 'sm'].includes(sizes[size]) },
        `ri-${iconRight}`,
      ]"
    />
    <div
      v-if="$slots['append-right'] && !loading"
      class="ml-2 flex items-center justify-center"
      :class="[iconSizes[iconSize]]"
    >
      <slot name="append-right" />
    </div>
  </button>
</template>
